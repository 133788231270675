const pathRewrite = '/api';
export const dyjAPI = {
  getLatestSmpls: pathRewrite + '/get_latest_smpls_from_dyj',
  getAllMtrls: pathRewrite + '/get_mtrls_from_dyj',
  getCompsList: pathRewrite + '/get_mtrl_comps_from_dyj',
  getColorList: pathRewrite + '/get_mtrl_colors_from_dyj',
  getAllSmpls: pathRewrite + '/get_smpls_from_dyj',
  getNpsmsFromDyj: pathRewrite + '/get_npsms_from_dyj',
  getSmplList: pathRewrite + '/smplList',
  getSalesContract: pathRewrite + '/earp/getSalesContract',
  getProcurement: pathRewrite + '/earp/getProcurement',
  getShipping: pathRewrite + '/earp/getShipping',
  getWeeklyPoReport: pathRewrite + '/earp/getWeeklyPoReport',
  exportExcelSalesContract: pathRewrite + '/earp/exportExcelSalesContract',
  exportExcelShipping: pathRewrite + '/earp/exportExcelShipping',
  exportExcelProcurement: pathRewrite + '/earp/exportExcelProcurement',
  exportWeeklyPoReport: pathRewrite + '/earp/exportWeeklyPoReport'
};
