export const downloadFile = async ({ fileUrl, fileName, type = '', open = false }) => {
  fileUrl = fileUrl.indexOf('manenvip') === -1 ? process.env.VUE_APP_FILE_URL + fileUrl + type : '' + fileUrl + type;
  if (open) return window.open(fileUrl);
  try {
    const headers = new Headers({ 'Content-Disposition': 'attachment' });
    const response = await fetch(fileUrl, { method: 'GET', headers });
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName; // 设置下载的文件名
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('文件下载失败:', error);
  }
};
